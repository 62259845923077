<template>
  <nav-bar/>
  <router-view></router-view>
  <notifications position="bottom right" />
</template>

<script>
// Import Bootstrap an BootstrapVue CSS files (order is important)
// @import 'bulma/css/bulma.css'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/sass/app.sass'
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>
