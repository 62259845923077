import { createRouter, createWebHistory } from 'vue-router'

const Home = () => import('@/pages/Homepage.vue')
const OrderForm = () => import('@/pages/OrderForm.vue')
const OrderList = () => import('@/pages/OrderList.vue')

const routes = [
    { path: '/', component: Home, name: 'Homepage' },
    { path: '/order-list', component: OrderList, name: 'Order List', meta: {pageTitle: 'Daftar Order'} },
    { path: '/order/:serviceName', component: OrderForm, name: 'Order Form', meta: {pageTitle: 'Survei Permasalahan'} }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router