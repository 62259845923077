import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import Notifications from '@kyvg/vue3-notification'
import './registerServiceWorker'
// import VuePwaInstallPlugin from "vue-pwa-install";

window.fcm_token = null


const app = createApp(App)
// app.use(VuePwaInstallPlugin)
app.use(router)
app.use(Notifications)
setTimeout(function () {
    app.mount('#app')
}, 1000);