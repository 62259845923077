<template>
  <Transition>
  <div v-if="showAboutContent" class="about-us-modal modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
          <div class="box">
              <div class="has-text-centered mb-2"><img src="/img/logo-width.png" class="logo" /></div>
              <!-- <h3 class="is-size-3 has-text-centered">GeDoor</h3>
              <h3 class="is-size-5 has-text-centered mb-1">Jasa Tukang Online</h3> -->
              <div class="content-media-about-us">
                  <p>Kami hadir sebagi solusi jasa pertukangan untuk anda mulai dari perbaikan rumah, kantor dan lain sebagainya. Mulai dari kategori layanan Renovasi hingga Bangun baru, semua tersedia didalam gengaman anda.</p>

                  <p>Ditangani oleh sarjana teknik sipil, sarjana teknik arsitektur dan tukang yang  berpengalaman dibidangnya. Jangan biarkan kerusakan rumah anda berlama lama!</p>
              </div>
          </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click.prevent="showAboutContent = false"></button>
  </div>
  </Transition>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="mobile-container">
      <div class="navbar-brand">
        <a v-if="isHomepage" class="navbar-item" href="https://app.gedoor.id">
          <img src="/img/logo-width.png" />
        </a>
        <a v-else class="navbar-item back-button" href="#" @click.prevent="goBack">
          <img src="/img/back-button.png" alt="">
        </a>
        <span v-if="!isHomepage" class="page-text has-text-primary">{{getPageTitle}}</span>
        <span v-if="!isOrderForm" class="end-nav-button" >
          <a href="#" @click.prevent="showAboutContent = true" to="order-list" style="margin-right: 15px;">
            <img src="/img/about-blue.png" alt="">
          </a>
          <router-link class="end-nav-button" to="order-list">
            <img src="/img/riwayat.png" alt="">
          </router-link>
        </span>
      </div>
    </div>
  </nav>
  <Transition>
    <div v-if="notification.show" class="notification is-primary bottom-notification">
      <button class="delete" @click.prevent="notification.show = false"></button>
      <div style="cursor: pointer;" @click.prevent="$router.push('/order-list'); notification.show = false">
        <h3>{{notification.title}}</h3>
        <p>{{notification.body}}</p>
      </div>
    </div>
  </Transition>
</template>

<script>
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCbyls09S9QvXLNGvInufdEaKcCRt-x2ao',
  authDomain: 'gedoor-web-apps.firebaseapp.com',
  projectId: 'gedoor-web-apps',
  storageBucket: 'gedoor-web-apps.appspot.com',
  messagingSenderId: '71409474751',
  appId: '1:71409474751:web:875a2cb4c3dac3b01cfa0a',
  measurementId: 'G-94HNZWP9XN'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)


export default {
  data () {
    return {
      showAboutContent: false,
      notification: {
        show:false,
        title: null,
        body: null
      },
      user: {
          id: null,
          nama: null,
          no_wa: null
      }
      // isHomepage: true
    }
  },
  computed: {
    isHomepage () {
      return this.$route.name === 'Homepage'
    },
    isOrderForm () {
      return this.$route.name === 'Order Form' || this.$route.name === 'Order List'
    },
    getPageTitle () {
      return this.$route.meta.pageTitle
    }
  },
  mounted () {
    if (localStorage.getItem('myaccount') !== null && localStorage.getItem('myaccount') !== '') {
        const myaccount = JSON.parse(localStorage.getItem('myaccount'))
        this.user.id = myaccount.id
        this.user.nama = myaccount.name
        this.user.no_wa = myaccount.phone_number
    }
    this.initFcm()
    this.receiveMessageFcm()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    initFcm () {
      getToken(messaging, { vapidKey: 'BL8tYoSBCJpUNbAL0WV-irf0skXDVHha8l8j66MSiNbBdSvgmPN5sB8zTutxf8utmoxak574UcJ99w4hiT-NH28' }).then((currentToken) => {
        if (currentToken) {
          window.fcm_token = currentToken
          if (this.user.id !== null) {
            fetch(process.env.VUE_APP_BASE_URL + 'api/notification-token', {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                },
                body: JSON.stringify({user_id: this.user.id, token: currentToken}),
            }).then(() => {
            }).catch(error => {
              console.error(error)
            })
          }
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      })
    },
    receiveMessageFcm () {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        this.notification.show = false
        this.notification.title = payload.data.title
        this.notification.body = payload.data.body
        this.notification.show = true
        setTimeout(() => {
          this.notification.show = false
        }, 5000)
      })
    }
  }
}
</script>
